import { default as abuse_45reportoWVT8heceRMeta } from "/usr/src/nuxt-app/pages/abuse-report.vue?macro=true";
import { default as highfivei5ZBu4To5dMeta } from "/usr/src/nuxt-app/pages/highfive.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as _91token_935OO8CQzCorMeta } from "/usr/src/nuxt-app/pages/kerdoiv/[token].vue?macro=true";
import { default as panaszbejelenteslPgCX6ndoYMeta } from "/usr/src/nuxt-app/pages/panaszbejelentes.vue?macro=true";
import { default as index9qXKigRYNLMeta } from "/usr/src/nuxt-app/pages/policy/index.vue?macro=true";
import { default as old_policyGKOaUzZRdWMeta } from "/usr/src/nuxt-app/pages/policy/old_policy.vue?macro=true";
import { default as policy_deH5iug726eAMeta } from "/usr/src/nuxt-app/pages/policy/policy_de.vue?macro=true";
import { default as policy_en75JF2RbwraMeta } from "/usr/src/nuxt-app/pages/policy/policy_en.vue?macro=true";
import { default as policy_hu6iRrHXSUU4Meta } from "/usr/src/nuxt-app/pages/policy/policy_hu.vue?macro=true";
import { default as qualityBAD53lNCVTMeta } from "/usr/src/nuxt-app/pages/quality.vue?macro=true";
import { default as registration80CUZ2lMpqMeta } from "/usr/src/nuxt-app/pages/registration.vue?macro=true";
export default [
  {
    name: "abuse-report",
    path: "/abuse-report",
    meta: abuse_45reportoWVT8heceRMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/abuse-report.vue")
  },
  {
    name: "highfive",
    path: "/highfive",
    component: () => import("/usr/src/nuxt-app/pages/highfive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "kerdoiv-token",
    path: "/kerdoiv/:token()",
    meta: _91token_935OO8CQzCorMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/kerdoiv/[token].vue")
  },
  {
    name: "panaszbejelentes",
    path: "/panaszbejelentes",
    meta: panaszbejelenteslPgCX6ndoYMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/panaszbejelentes.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/usr/src/nuxt-app/pages/policy/index.vue")
  },
  {
    name: "policy-old_policy",
    path: "/policy/old_policy",
    meta: old_policyGKOaUzZRdWMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/policy/old_policy.vue")
  },
  {
    name: "policy-policy_de",
    path: "/policy/policy_de",
    component: () => import("/usr/src/nuxt-app/pages/policy/policy_de.vue")
  },
  {
    name: "policy-policy_en",
    path: "/policy/policy_en",
    component: () => import("/usr/src/nuxt-app/pages/policy/policy_en.vue")
  },
  {
    name: "policy-policy_hu",
    path: "/policy/policy_hu",
    component: () => import("/usr/src/nuxt-app/pages/policy/policy_hu.vue")
  },
  {
    name: "quality",
    path: "/quality",
    component: () => import("/usr/src/nuxt-app/pages/quality.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/usr/src/nuxt-app/pages/registration.vue")
  }
]