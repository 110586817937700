<template>
  <v-container
    style="height: 100vh !important;"
    fluid
    class="fill-height banner-wrapper1 bg-danger-dark"
  >
    <!-- <div class="banner-wrapper bg-danger-dark w-100 h-100"> -->
    <!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        md="6"
        lg="4"
        class="d-flex align-center justify-center"
      >
        <div class="text-center">
          <h1 class="banner-title font-weight-bold text-white svg">
            Opps, 404 - Error
          </h1>
          <h4 class="banner-subtitle mr-0 text-white font-weight-regular svg">
            The page you are looking for is not available please go back to
            homepage
          </h4>
          <div class="mt-16 pt-2">
            <v-btn
              large
              to="/"
              class="text-white font-weight-bold"
              color="vsz-mustar"
              elevation="4"
            >
              Back to Homepage
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- -----------------------------------------------
            End Banner
        ----------------------------------------------- -->
    <!-- </div> -->
  </v-container>
</template>

<style lang="scss" scoped>
.v-container {
  padding: 0 !important;
  max-width: 100% !important;
}

.v-main {
  margin-top: 0px !important;
}
</style>
