import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineNuxtPlugin((nuxtApp) => {
  if (typeof window !== "undefined") {
    nuxtApp.vueApp.component("Carousel", Carousel);
    nuxtApp.vueApp.component("Slide", Slide);
    nuxtApp.vueApp.component("Pagination", Pagination);
  }
});
