import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_date_uw7qb7IA4F from "/usr/src/nuxt-app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n-date.js";
import vuetify_i18n_H4uUvhyrU1 from "/usr/src/nuxt-app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_KAumv2pUni from "/usr/src/nuxt-app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/usr/src/nuxt-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_WPDdiystzB from "/usr/src/nuxt-app/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import vuetify_no_client_hints_kyYn5AnnAK from "/usr/src/nuxt-app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/usr/src/nuxt-app/plugins/Vue3Lottie.client.ts";
import aos_client_rDSYU13oOX from "/usr/src/nuxt-app/plugins/aos.client.ts";
import country_flag_icon_client_GGj1B0buTQ from "/usr/src/nuxt-app/plugins/country-flag-icon.client.ts";
import countup_client_Td9YxNkDS0 from "/usr/src/nuxt-app/plugins/countup.client.ts";
import masonry_client_6cDXUKSg0z from "/usr/src/nuxt-app/plugins/masonry.client.ts";
import notification_client_B9MZmzG6b3 from "/usr/src/nuxt-app/plugins/notification.client.ts";
import recaptcha_85gNSCNFUU from "/usr/src/nuxt-app/plugins/recaptcha.ts";
import shortkey_client_GxjDLOtgfj from "/usr/src/nuxt-app/plugins/shortkey.client.ts";
import smooth_scroll_jeevVkQy4J from "/usr/src/nuxt-app/plugins/smooth-scroll.ts";
import vue_calendar_client_vOfuLjSZKG from "/usr/src/nuxt-app/plugins/vue-calendar.client.ts";
import vue3_carousel_client_wTHHlcAgnN from "/usr/src/nuxt-app/plugins/vue3-carousel.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/usr/src/nuxt-app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_date_uw7qb7IA4F,
  vuetify_i18n_H4uUvhyrU1,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  nuxt_plugin_WPDdiystzB,
  vuetify_no_client_hints_kyYn5AnnAK,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  Vue3Lottie_client_bMZiOS6AB0,
  aos_client_rDSYU13oOX,
  country_flag_icon_client_GGj1B0buTQ,
  countup_client_Td9YxNkDS0,
  masonry_client_6cDXUKSg0z,
  notification_client_B9MZmzG6b3,
  recaptcha_85gNSCNFUU,
  shortkey_client_GxjDLOtgfj,
  smooth_scroll_jeevVkQy4J,
  vue_calendar_client_vOfuLjSZKG,
  vue3_carousel_client_wTHHlcAgnN,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]